import mobileMenu from "./modules/mobileMenu";
import userMenu from "./modules/userMenu";
import dropdown from "./modules/dropdown";
//import searchBar from "./modules/searchBar";
import favorites from "./modules/favorites";
import cartAjax from "./modules/cartAjax";

import tabs from "./modules/tabs";
import accordion from "./modules/accordion";
import filters from "./modules/filters";
import SideModal from "./classes/SideModal";
import pagination from "./modules/pagination";

//require('./app_old.js');



(function () {

    mobileMenu();
    // userMenu();
    dropdown();
  //  searchBar();
    favorites();
    cartAjax();

    tabs();
    accordion();
    filters();
    // pagination();

    promoSideModal();
    function promoSideModal() {
        const sideModalElements = document.querySelectorAll('.promo-details-modal')
        const opensideModals = document.querySelectorAll('.show-promo-details-modal')
        const closeSideModals = document.querySelector('.close-promo-details-modal')


        opensideModals.forEach(opensideModal => {
            opensideModal.addEventListener('click', function () {
                opensideModal.dataset.promo
                console.log(opensideModal.dataset.promo);
                // document.body.classList.add('overflow-hidden')
                sideModalElements.forEach(sideModalElement => {
                    const closeSideModal = sideModalElement.querySelector('.close-promo-details-modal')
                    if (opensideModal.dataset.promo === sideModalElement.dataset.promo) {
                        sideModalElement.classList.remove('-translate-x-full')
                        const buttonTabs = sideModalElement.querySelectorAll('[role=tab]');
                        buttonTabs[0].click();

                    }
                    closeSideModal.addEventListener('click', function () {
                        this.parentNode.classList.add('-translate-x-full');
                    })
                });
            })
        });
        // opensideModal.addEventListener('click', () => {
        //     sideModalElement.classList.remove('-translate-x-full')
        // })
        // closeSideModal.addEventListener('click', () => {
        //     sideModalElement.classList.add('-translate-x-full')
        // })
    }

    scrollableTabs();
    function scrollableTabs() {
        const tabs = document.querySelectorAll('.scrollable-tab');
        tabs.forEach(tab => {
            tab.addEventListener('click', (e) => {
                e.preventDefault();
                tab.parentNode.querySelector('[data-active="true"]')

                callPromoAsync(tab.children[0].getAttribute('href'), tab.dataset.value)

                tab.parentNode.querySelectorAll('[data-active="true"]').forEach(el => {
                    el.dataset.active = false;
                    el.children[0].classList.remove('border-purple-800')
                    el.children[0].classList.add('border-transparent')
                    el.children[0].classList.remove('text-gray-800')
                    el.children[0].classList.add('text-gray-600')

                });

                tab.dataset.active = true;
                tab.parentNode.scrollLeft = (tab.offsetLeft - 16) - ((tab.parentNode.offsetWidth / 2) - (tab.offsetWidth / 2));
                tab.children[0].classList.remove('border-transparent')
                tab.children[0].classList.add('text-gray-800')
                tab.children[0].classList.remove('text-gray-600')
                tab.children[0].classList.add('border-purple-800');
            })
        })
    }

    function callPromoAsync(url, triggerValue) {
        const ajaxUrl = (triggerValue == 'all' || triggerValue === 'bt') ? url + '?async=1' : url + '&async=1';

        const contentDestination = document.querySelector('#lista-promo-modello');
        const xhr = new XMLHttpRequest();

        xhr.open('GET', ajaxUrl, true);
        xhr.send();
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && xhr.status == 200) {
                const response = xhr.responseText;
                contentDestination.innerHTML = response;
                if (triggerValue === 'bt') {
                    window.history.pushState(url, '', url);

                } else {

                    window.history.pushState(triggerValue, '', url);
                }

                tabs();
                callInfoPromoAsync();
                promoSideModal();
                favorites();
            }
        }

    }

    window.addEventListener("popstate", function (e) {
        document.location.reload(true)
    });


    callInfoPromoAsync();
    function callInfoPromoAsync() {
        const tabs = document.querySelectorAll('[role="tab"]');
        const tabPanels = document.querySelectorAll('[role="tabpanel"]');

        tabs.forEach(tab => {
            tab.addEventListener("click", function () {
                const promoId = tab.dataset.content.split('-')[0];
                const promoInfo = tab.dataset.content.split('-')[1];

                const xhr = new XMLHttpRequest();

                xhr.open('GET', 'nlt/get_info_modello/' + promoId + '/' + promoInfo, true);
                xhr.send();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == 4 && xhr.status == 200) {
                        const response = xhr.responseText;

                        tabPanels.forEach(tabPanel => {

                            if (tabPanel.dataset.content === tab.dataset.content) {

                                tabPanel.innerHTML = response;
                            }
                        });

                    }
                }
            });
        });
    }






})();