

export default function mobileMenu() {
    const hamburgerButton = document.querySelector('#hambunger-button');
    const mobileMenu = document.querySelector('#mobile-menu');
    const hasChildsOnSide = document.querySelectorAll('.has-child-on-side');
    const goBackButton = document.querySelector('#go-back-button');
    const closeMenuButton = document.querySelector('#close-menu-button');
    const currentMenuPosition = document.querySelector('#current-menu-position');

    hamburgerButton.addEventListener('click', function () {
        mobileMenu.classList.remove('-translate-x-full')
        document.body.classList.add('overflow-hidden');
    })

    hasChildsOnSide.forEach(hasChildOnSide => {
        hasChildOnSide.addEventListener("click", (e) => {
            const target = e.target;
            const parent = target.parentNode;
            parent.querySelector('.is-child-on-side').classList.remove('-translate-x-full');
            currentMenuPosition.textContent = target.dataset.label;
            goBackButton.classList.remove('opacity-0');
            goBackButton.classList.remove('pointer-events-none');
        });
    });

    goBackButton.addEventListener('click', () => {

        hasChildsOnSide.forEach(hasChildOnSide => {
            hasChildOnSide.querySelector('.is-child-on-side').classList.add('-translate-x-full');
        });
        goBackButton.classList.add('opacity-0');
        goBackButton.classList.add('pointer-events-none');
        currentMenuPosition.innerHTML = '';

    })

    closeMenuButton.addEventListener('click', () => {
        mobileMenu.classList.add('-translate-x-full')
        hasChildsOnSide.forEach(hasChildOnSide => {
            hasChildOnSide.querySelector('.is-child-on-side').classList.add('-translate-x-full');
        });
        goBackButton.classList.add('opacity-0');
        goBackButton.classList.add('pointer-events-none');
        currentMenuPosition.innerHTML = '';
        document.body.classList.remove('overflow-hidden');
    })
}


